import { IBrandRoutes } from './types';
import { LazyRoute, lazyWithFallback } from './util';

const Catering: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('components/catering')).Catering,
}));

export const brandRoutes: IBrandRoutes = {
  'routes.catering': Catering,
};

export const fullPageBrandRoutes: IBrandRoutes = {};
