import React from 'react';

import { Animation } from 'components/lottie-animations/logo';

type Props = {
  width?: string;
  height?: string;
};

export const Logo: React.FC<Props> = props => (
  <React.Suspense fallback={null}>
    <Animation {...props} />
  </React.Suspense>
);
