import React, { useEffect, useRef } from 'react';

import lottie, { AnimationItem } from 'lottie-web';
import styled from 'styled-components';

import { IAnimationContainerProps, ILottieAnimationProps, LottieOptions } from './types';

const useLottieAnimation = (
  containerRef: React.RefObject<HTMLDivElement>,
  options: LottieOptions,
  shouldPause: boolean = false,
  onComplete?: VoidFunction
) => {
  const animationInstance = useRef<AnimationItem | null>(null);
  const { animationData, autoplay = false, loop = false } = options;

  useEffect(() => {
    let instance: AnimationItem;
    if (containerRef.current) {
      instance = lottie.loadAnimation({
        container: containerRef.current,
        animationData,
        autoplay,
        loop,
      });
      animationInstance.current = instance;

      if (onComplete) {
        instance.addEventListener('complete', onComplete);
      }
    }

    return () => {
      if (instance) {
        // removing event listeners before destroying the instance.
        // otherwise it can lead to weird errors.
        if (onComplete) {
          instance.removeEventListener('complete', onComplete);
        }
        instance.destroy();
      }
    };
  }, [animationData, autoplay, loop, containerRef, onComplete]);

  useEffect(() => animationInstance.current?.[shouldPause ? 'pause' : 'play'](), [shouldPause]);
};

const AnimationContainer = styled.div<IAnimationContainerProps>`
  height: ${props => props.$height || '100%'};
  width: ${props => props.$width || '100%'};
`;

export const LottieAnimation: React.FC<ILottieAnimationProps> = ({
  options,
  isPaused = false,
  height,
  width,
  onComplete,
  ...restProps
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useLottieAnimation(containerRef, options, isPaused, onComplete);
  return <AnimationContainer {...restProps} ref={containerRef} $height={height} $width={width} />;
};
