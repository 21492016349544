import React from 'react';

import { IconCluckNorris } from 'components/icons/cluck-norris';

interface IIcon {
  width?: number;
}

export const Icon = ({ width }: IIcon) => {
  const s = width || 160;
  return <IconCluckNorris width={s} height={s} />;
};
