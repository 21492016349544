import React from 'react';

import styled from 'styled-components';

import { IconBook } from 'components/icons/book';
import { IconMobileNavHome } from 'components/icons/mobile-nav-home';
import { IconRefresh } from 'components/icons/refresh';
import { IconTag } from 'components/icons/tag';
import { routes } from 'utils/routing';

import { NavLink, iconSize } from './helpers';
import { getAboutLink, getAccountLink } from './nav-links-item';
import { IconWrap, NavLinkText } from './styled';
import { GetNavLinksParams, NavigationMobileTab } from './types';

const BrandLogo = styled(IconMobileNavHome)<{ viewBox: string }>`
  height: 25px;
`;

const homeTextKey = 'home';
const orderTextKey = 'order';
const reorderTextKey = 'reorder';
const offersTextKey = 'offers';

export const getNavLinks = ({
  menuHref,
  formatMessage,
  enableAccountInFooter,
  enableOffers,
  logMobileNavOrderEvent,
}: GetNavLinksParams): NavigationMobileTab[] => {
  const navRoutes = [
    {
      selectedHrefs: [routes.base],
      Component: () => (
        <NavLink
          data-testid="home-mobile"
          to={routes.base}
          requiresExactMatch
          navBarText={homeTextKey}
        >
          <IconWrap aria-hidden>
            <BrandLogo viewBox="0 0 24 24" />
          </IconWrap>
          <NavLinkText>{formatMessage({ id: homeTextKey })}</NavLinkText>
        </NavLink>
      ),
    },
    {
      selectedHrefs: [menuHref],
      Component: () => (
        <NavLink
          data-testid="order-mobile"
          onClick={logMobileNavOrderEvent}
          to={menuHref}
          navBarText={orderTextKey}
        >
          <IconWrap aria-hidden>
            <IconBook height={iconSize} />
          </IconWrap>
          <NavLinkText>{formatMessage({ id: orderTextKey })}</NavLinkText>
        </NavLink>
      ),
    },
    {
      selectedHrefs: [routes.orders],
      Component: () => (
        <NavLink data-testid="recent-orders-mobile" to={routes.orders} navBarText={reorderTextKey}>
          <IconWrap aria-hidden>
            <IconRefresh width={iconSize} />
          </IconWrap>
          <NavLinkText>{formatMessage({ id: reorderTextKey })}</NavLinkText>
        </NavLink>
      ),
    },
    enableAccountInFooter ? getAccountLink({ formatMessage }) : getAboutLink({ formatMessage }),
  ];

  if (enableOffers) {
    navRoutes.splice(1, 0, {
      selectedHrefs: [routes.offers],
      Component: () => (
        <NavLink data-testid="offers-mobile" to={routes.offers} navBarText={offersTextKey}>
          <IconWrap aria-hidden>
            <IconTag height={iconSize} />
          </IconWrap>
          <NavLinkText>{formatMessage({ id: offersTextKey })}</NavLinkText>
        </NavLink>
      ),
    });
  }

  return navRoutes;
};
