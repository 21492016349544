import React from 'react';

/* A note when using this component - you will need to pass a fill color if you want anything other than black. */

export const IconTag = props => (
  <svg viewBox="0 0 22 22" {...props}>
    <title>Tag</title>
    <path
      d="M19.8 1.662L11.994.027a.572.572 0 0 0-.64.213L.214 11.404a.688.688 0 0 0 0 .996l4.684 4.693 4.684 4.694c.284.284.71.284.993 0L21.787 10.55c.142-.142.213-.427.213-.64L20.368 2.09a.93.93 0 0 0-.568-.427zm-2.239 4.899a1.502 1.502 0 0 1-2.122-2.122 1.502 1.502 0 0 1 2.122 2.122z"
      fillRule="nonzero"
    />
  </svg>
);
