import { themeBase } from './theme-base';

export const theme = {
  ...themeBase,

  delivery: {
    ...themeBase.delivery,
    icon: themeBase.delivery.icon,
    height: '2.15rem',
    width: '2.4rem',
  },

  pickup: {
    ...themeBase.pickup,
    icon: 'poppyBag',
    height: '2.15rem',
    width: '2.15rem',
  },

  catering: {
    ...themeBase.catering,
    delivery: {
      ...themeBase.catering.delivery,
      icon: themeBase.catering.delivery.icon,
      height: '2.15rem',
      width: '2.4rem',
    },
  },
};
