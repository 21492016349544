import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IconLoyaltyLogoPlk } from 'components/icons/loyalty-logo-plk';
import { Picture } from 'components/picture';
import { useFeatureLoyaltyUi } from 'hooks/use-feature-loyalty-ui';

const PictureContainer = styled.div`
  min-width: 4.75rem;
  min-height: 2rem;
`;

const DefaultLoyaltyLogo = styled(IconLoyaltyLogoPlk)`
  width: 6rem;
  height: 3rem;
`;

export interface ILoyaltyRewardsLogo {
  inverted?: boolean;
}

export const LoyaltyRewardsLogo = ({ inverted, ...props }: ILoyaltyRewardsLogo) => {
  const { featureLoyaltyUiLoading, featureLoyaltyUi } = useFeatureLoyaltyUi();
  const { formatMessage } = useIntl();

  if (featureLoyaltyUiLoading) {
    return null;
  }

  // If custom logo hasn't been uploaded in Sanity, use default one
  if (!featureLoyaltyUi) {
    return <DefaultLoyaltyLogo />;
  }

  return (
    <PictureContainer data-testid="plk-rewards-logo" {...props}>
      <Picture
        image={
          inverted
            ? featureLoyaltyUi?.rewardsLogoInverted?.locale
            : featureLoyaltyUi?.rewardsLogo?.locale
        }
        alt={formatMessage({ id: 'homepage' })}
        objectFitContain
      />
    </PictureContainer>
  );
};
