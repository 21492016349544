export const storeMarkerActive = `
<svg width="48" height="60" viewBox="0 0 48 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-inside-1" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.0326 46.9532C40.856 43.9471 48 34.8078 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 34.3472 6.54807 43.1651 15.7264 46.5358L23.5 60L31.0326 46.9532Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.0326 46.9532C40.856 43.9471 48 34.8078 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 34.3472 6.54807 43.1651 15.7264 46.5358L23.5 60L31.0326 46.9532Z" fill="${Styles.color.highlight}"/>
<path d="M31.0326 46.9532L30.1547 44.0845L29.0252 44.4302L28.4345 45.4532L31.0326 46.9532ZM15.7264 46.5358L18.3245 45.0358L17.781 44.0944L16.7606 43.7197L15.7264 46.5358ZM23.5 60L20.9019 61.5L23.5 66L26.0981 61.5L23.5 60ZM45 24C45 33.4524 38.7526 41.4534 30.1547 44.0845L31.9104 49.8219C42.9593 46.4408 51 36.1633 51 24H45ZM24 3C35.598 3 45 12.402 45 24H51C51 9.08831 38.9117 -3 24 -3V3ZM3 24C3 12.402 12.402 3 24 3V-3C9.08831 -3 -3 9.08831 -3 24H3ZM16.7606 43.7197C8.72508 40.7687 3 33.0491 3 24H-3C-3 35.6454 4.37106 45.5615 14.6922 49.3519L16.7606 43.7197ZM26.0981 58.5L18.3245 45.0358L13.1284 48.0358L20.9019 61.5L26.0981 58.5ZM28.4345 45.4532L20.9019 58.5L26.0981 61.5L33.6307 48.4532L28.4345 45.4532Z" fill="white" mask="url(#path-1-inside-1)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 19.5212L12.6733 22.738C12.6733 22.738 14.1024 20.6447 15.8636 22.0496C17.6251 23.4546 16.3933 26.3382 16.2311 27.9995C16.0251 30.1134 16.548 31.8226 17.4009 33.181C18.4862 34.9088 21.0064 36.3463 23.7124 36.8548C23.9191 36.893 24.1182 36.9226 24.3144 36.9467L24.5955 39.9203H26.6342L26.9085 37.0141C31.5867 36.6398 38.2042 32.0757 35.2596 24.3919C33.9049 20.8564 31.0335 15.5783 31.0335 15.5783L31.0665 15.5694C32.2024 15.2817 32.4225 13.8111 31.0949 13.0221L32.0442 12.1461L30.6562 11.5789L31.1744 9.68789L28.1951 10.8652C28.198 10.8628 28.2033 10.8586 28.2033 10.8586C28.2033 10.8586 27.4482 9.28815 26.3004 9.27582C25.3322 9.26531 24.876 9.96217 24.876 9.96217C24.876 9.96217 24.6187 9.24446 23.9564 9.04849C23.294 8.8527 22.2149 9.23703 22.0778 10.4352C21.924 11.7785 23.0122 12.4697 23.3702 12.5839C23.7951 12.7192 24.3825 12.9927 25.6142 12.4743C25.6122 13.7068 25.6071 16.5637 25.6047 17.9997C25.6015 19.8617 25.7696 22.8796 24.9684 24.5162C24.1671 26.1529 23.2535 26.8345 22.0444 26.6933C20.6935 26.5356 20.2311 25.2738 20.7005 22.9329C21.1702 20.5917 21.5664 18.2629 19.5978 16.5278C17.6293 14.7927 14.0867 14.6024 10 19.5212ZM28.454 12.0053C28.7584 11.8501 29.1467 12.0007 29.3215 12.3414C29.4962 12.6822 29.3911 13.0841 29.0871 13.2393C28.7827 13.3943 28.3944 13.2438 28.2196 12.903C28.0449 12.5624 28.1498 12.1603 28.454 12.0053Z" fill="white"/>
</svg>

`;
